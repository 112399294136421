<template>
  <v-row justify="center" class="ma-0 pa-0">
    <v-col cols="12" class="my-4">
      <h1 class="text-h4 primary--text text-center">Import de comptes</h1>
    </v-col>
    <v-col cols="12" sm="8" md="8">
      <v-card>
        <v-stepper alt-labels v-model="currentStep">
          <v-stepper-header>
            <v-stepper-step step="1"> Début </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2"> Traitement </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Résumé </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form lazy-validation ref="import">
                <v-file-input
                  v-model="file"
                  label="Fichier d'import (.csv)"
                  accept=".csv"
                  name="csv"
                  filled
                  prepend-icon="mdi-file"
                  :rules="[(v) => !!v || 'Un fichier CSV est requis.']"
                  required
                ></v-file-input>

                <v-btn
                  name="Ajouter un utilisateur"
                  height="44px"
                  color="primary"
                  dark
                  class="AccessButton pl-3 pr-1"
                  tile
                  elevation="0"
                  fab
                  @click="validate"
                  width="150"
                >
                  Importer
                  <v-spacer />
                  <v-icon class="ml-5"> mdi-upload </v-icon>
                </v-btn>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row class="ma-0 justify-center">
                <v-col class="text-subtitle-1 text-center" cols="12">
                  <h1 class="text-h5 primary--text text-center">
                    Traitement du fichier en cours ({{ this.actualAccount }}/{{
                      this.totalAccount
                    }})
                  </h1>
                </v-col>
                <v-col cols="6">
                  <v-progress-linear
                    color="primary"
                    :value="currentPercent"
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-row class="ma-0 justify-center" v-if="errors.length === 0">
                <v-col class="text-subtitle-1 text-center" cols="12">
                  <v-icon size="40" color="success">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                </v-col>
                <v-col cols="6">
                  <p class="text-center">
                    Les comptes ont été créé avec succès !
                  </p>
                </v-col>
              </v-row>
              <v-row class="ma-0 justify-center" v-else>
                <v-col class="text-subtitle-1 text-center" cols="12">
                  <v-icon size="40" color="error">
                    mdi-alert-circle-outline
                  </v-icon>
                </v-col>
                <v-col cols="8">
                  <p class="text-center">
                    Des erreurs se sont produites lors de la création des
                    comptes.
                  </p>
                  <p>Liste des erreurs :</p>
                  <li v-for="err in errors" :key="err">
                    {{ err }}
                    <br />
                  </li>
                </v-col>
              </v-row>

              <v-btn
                name="Retour"
                height="44px"
                color="primary"
                dark
                class="AccessButton pl-3 pr-1"
                tile
                elevation="0"
                fab
                @click="reset"
                width="110"
              >
                Retour
                <v-spacer />
                <v-icon class="ml-3 mr-2"> mdi-home </v-icon>
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import AdminService from "@/services/AdminService";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";
import store from "@/store";

let stompClient = null;

export default {
  components: {},
  data: () => ({
    currentStep: 1,
    file: null,
    errors: [],
    currentPercent: 0,
    totalAccount: 0,
    actualAccount: 0,
  }),
  destroyed() {
    if (stompClient !== null) {
      stompClient.disconnect();
    }
  },
  methods: {
    validate: function () {
      if (this.$refs.import.validate()) {
        let uniqueId =
          store.state.user.identifiant +
          "-" +
          Math.random().toString(36).substr(2, 9);
        const socket = new SockJS(
          `${process.env.VUE_APP_BASE_URL}batch/socket`
        );
        stompClient = Stomp.over(socket);
        stompClient.debug = () => {};
        let app = this;
        stompClient.connect({}, function () {
          stompClient.subscribe(
            `/progress/importAccountFromCsvJob/${uniqueId}`,
            function (messageOutput) {
              if (messageOutput.body) {
                let json = JSON.parse(messageOutput.body);
                app.currentPercent = json.percentageComplete;
                app.actualAccount = json.writeCount + json.skipCount;
                app.totalAccount = json.totalCount;
                if (json.messages != null) {
                  app.errors = json.messages;
                }
                if (json.status === "TERMINÉ") {
                  app.currentStep = 3;
                  app.totalAccount = 0;
                  app.currentPercent = 0;
                  app.actualAccount = 0;
                }
              }
            }
          );
        });

        this.currentStep = 2;
        const formData = new FormData();
        formData.append("file", this.file);
        AdminService.importFromCSV(formData, uniqueId)
          .then((res) => {
            if (!res.data.messages.includes("IMPORT TERMINÉ SANS ERREUR")) {
              this.errors = res.data.messages;
            }
            this.currentStep = 3;
            this.totalAccount = 0;
            this.currentPercent = 0;
            this.actualAccount = 0;
          })
          .catch((e) => {
            if (e.response.status !== 499) {
              if (e.response) {
                if (typeof e.response.data.messages !== "undefined") {
                  this.errors = e.response.data.messages;
                } else {
                  this.errors = [
                    "Une erreur est survenue lors de l'import des comptes.",
                  ];
                }
              }
              this.currentStep = 3;
              this.totalAccount = 0;
              this.currentPercent = 0;
              this.actualAccount = 0;
            }
          });
      }
    },
    reset: function () {
      this.currentStep = 1;
      this.file = null;
      this.errors = [];
      this.totalAccount = 0;
      this.currentPercent = 0;
      this.actualAccount = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.AccessButton {
  border-radius: 4px;
  font-weight: 800;
  letter-spacing: 0;
}
</style>
