<template>
  <v-card
    height="100%"
    color="backgroundColor"
  >
    <ImportTab />
  </v-card>
</template>

<script>
import ImportTab from "@/components/ImportComponents/ImportTab";

export default {
  components: {
    ImportTab,
  },
};
</script>
